// Modals
// Design element Dialogs
// --------------------------------------------------
.modal-content {
  border: 0;

  // Modal header
  // Top section of the modal w/ title and dismiss

  .modal-header {
    border-bottom: none;

    & button {
      position: absolute;
      right: 27px;
      top: 24px;
      outline: 0;
      padding: 1rem;
      margin: -1rem -1rem -1rem auto;
    }
    .title {
      color: $black;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .modal-title {
      color: $black;
    }

    i.tim-icons {
      font-size: 16px;
    }
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    line-height: 1.9;

    p {
      color: $black;
    }
  }
  // Footer (for actions)
  .modal-footer {
    border-top: 0;
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    justify-content: space-between;

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;

      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }
}
.modal-backdrop {
  background: rgba(#fff, 1);
}

.modal {
  &.modal-default {
    @include modal-colors($white, $black);
  }

  &.modal-primary {
    @include modal-colors($primary, $white);
  }

  &.modal-danger {
    @include modal-colors($danger, $white);
  }

  &.modal-warning {
    @include modal-colors($warning, $white);
  }

  &.modal-success {
    @include modal-colors($success, $white);
  }

  &.modal-info {
    @include modal-colors($info, $white);
  }

  .modal-header .close {
    color: $danger;
    text-shadow: none;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &.modal-black {
    .modal-content {
      // background: linear-gradient(to bottom, $black 0%, $black-states 100%);
      background-color: #1e8c93;
      color: rgba($white, 0.8);
      .modal-header {
        .modal-title,
        .title {
          color: rgba($white, 0.9);
        }
      }
      .modal-body {
        p {
          color: rgba($white, 0.8);
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }
}

.modal-search {
  .modal-dialog {
    margin: 20px auto;
    max-width: 650px;
    input {
      border: none;
      font-size: 17px;
      font-weight: 100;
    }
    span {
      font-size: 35px;
      color: $search-gray;
    }
  }
  .modal-content {
    .modal-header {
      padding: 24px;
    }
  }

  .modal-header .close {
    color: $dark-background;
    top: 30px !important;
  }

  .modal-footer {
    border-top: 2px solid #f9f9f9;
    margin: 0px 25px 20px;
  }
}
