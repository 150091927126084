.nav-tabs {
  border-bottom: 0.0625rem solid rgba(#fff,.3);
}

.nav-tabs .nav-link {
  color: $default-for-black;
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  cursor: pointer;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $default-for-black;
  background-color: $brand-inverse;
  border-color: #57575c rgba(#fff,.3) $brand-inverse;
  cursor: pointer;
}