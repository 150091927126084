/*
 =========================================================
 * Black Dashboard React v1.2.0 based on Black Dashboard - v1.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core

@import "black-dashboard-react/custom/functions";
@import "~bootstrap/scss/functions";

@import "black-dashboard-react/custom/variables";
@import "~bootstrap/scss/variables";

@import "black-dashboard-react/custom/mixins";
@import "~bootstrap/scss/mixins";

// Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Custom components

@import "black-dashboard-react/custom/alerts.scss";
@import "black-dashboard-react/custom/badges.scss";
@import "black-dashboard-react/custom/buttons.scss";
@import "black-dashboard-react/custom/text.scss";
@import "black-dashboard-react/custom/dropdown.scss";
@import "black-dashboard-react/custom/footer.scss";
@import "black-dashboard-react/custom/forms.scss";
@import "black-dashboard-react/custom/images.scss";
@import "black-dashboard-react/custom/modal.scss";
@import "black-dashboard-react/custom/navbar.scss";
@import "black-dashboard-react/custom/navtab.scss";
@import "black-dashboard-react/custom/type.scss";
@import "black-dashboard-react/custom/tables";
@import "black-dashboard-react/custom/checkboxes-radio";
@import "black-dashboard-react/custom/fixed-plugin";
@import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
@import "black-dashboard-react/custom/misc.scss";
@import "black-dashboard-react/custom/rtl.scss";
@import "black-dashboard-react/custom/input-group.scss";

// Vendor / Plugins

@import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards

@import "black-dashboard-react/custom/card";
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-plain";

// React differences
@import "black-dashboard-react/react-differences/react-differences";

.content {
  background-color: #1e8c93 !important;
}
.footer {
  background-color: #1e8c93 !important;
}
